import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react"
import { Uuid } from "../../reactor/Types/Primitives/Uuid"

export type SaveListener = {
    discard(): Promise<void>
    save(): Promise<void>
    isDirty: boolean
}

export type EditableResource = {
    query: string
    endpoint: string
    data: any
    dtoName: string
    putDtoName: string
}

export type EditableContext = {
    readonly isDirty: boolean
    /**
     * Notifies the context that something may have changed in the resource(s)
     * currently being edited.
     *
     * This will trigger all the change listeners to be probed for dirty states.
     * The result could either be that the resources are now dirty, or that they
     * are no longer dirty (set back to their original state).
     *
     */
    invalidate(): void
    saveListeners: Set<SaveListener>

    /** Whether we are currently in edit mode. */
    editing: boolean
    setEditing(editing: boolean): void

    /**
     * Can be set by editing components when they are focused, to let the
     * editable context know that we are currently editing something.
     *
     * This information can be used to e.g. disable resource refreshing, that
     * would otherwise happen per-keystroke.
     */
    focused: boolean

    save(forceDirty?: boolean): Promise<void>

    discardChanges(): Promise<void>
    addSaveListener(callback: SaveListener): void
    removeSaveListener(callback: SaveListener): void

    /** Whether e.g. scroll animations should be disabled in this context */
    disableAnimations?: boolean

    /**
     * A string that changes whenever there is an external change to the
     * document, that did not originate from a current editor PropView bound
     * to the document.
     *
     * This e.g. happens on Discard Changes.
     */
    versionKey: string
}

export const DummyEditableContext: EditableContext = {
    editing: false,
    invalidate() {},
    isDirty: false,
    saveListeners: new Set(),
    setEditing(editing: boolean) {},

    async save(forceDirty?: boolean) {},

    focused: false,

    async discardChanges() {},

    addSaveListener(callback: SaveListener) {},
    removeSaveListener(callback: SaveListener) {},

    disableAnimations: true,

    versionKey: "none",
}

export const EditableContext = createContext<EditableContext | null>(null)

export function useEditableContext() {
    const context = useContext(EditableContext)
    if (!context) throw new Error("No editable context")
    return context
}

/** Registers a function that will be called when the Save button is clicked. */
export function useSaveHook(save: SaveListener, ...deps: any[]) {
    const { addSaveListener, removeSaveListener } = useEditableContext()
    useEffect(() => {
        addSaveListener(save)
        return () => removeSaveListener(save)
    }, [addSaveListener, removeSaveListener, save, ...deps])
}

export type EditableOptions = {
    /** Whether this hook is enabled. Defaults to true */
    condition?: boolean
    /** Whether the current user can "set" data through this hook. Defaults to requires super user.
     * */
    canWrite?: boolean
    /** Whether the current user can "get" this hook. Defaults to true for all users. */
    canRead?: boolean
}

/**
 * Default implementation of EditableContext
 */
export function useEditableContextProvider() {
    const [version, setVersion] = useState({})
    const [versionKey, setVersionKey] = useState(() => Uuid())
    const [editing, setEditing] = useState(false)

    const saveListeners = useRef(new Set<SaveListener>()).current

    function getIsDirty() {
        return Array.from(saveListeners).some((listener) => listener.isDirty)
    }

    const addSaveListener = useCallback((listener: SaveListener) => saveListeners.add(listener), [])
    const removeSaveListener = useCallback(
        (listener: SaveListener) => saveListeners.delete(listener),
        []
    )
    const discardChanges = useCallback(async () => {
        // Need to snapshot the set, in case the save operation modifies
        // the set of listeners.
        const snapshotListeners = Array.from(saveListeners)
        for (const listener of snapshotListeners) {
            await listener.discard()
        }
        setVersionKey(Uuid())
        setVersion({})
    }, [])

    const save = useCallback(async (forceDirty?: boolean) => {
        if (getIsDirty() || forceDirty) {
            // Need to snapshot the set, in case the save operation modifies
            // the set of listeners.
            const snapshotListeners = Array.from(saveListeners)
            for (const listener of snapshotListeners) {
                await listener.save()
            }
        }
        // Need to allow a re-render before checking the isDirty state again
        setTimeout(() => setVersion({}), 1)
    }, [])
    const invalidate = useCallback(() => setVersion({}), [])

    const [focused, setFocused] = useState(false)

    return {
        saveListeners,
        get isDirty() {
            return getIsDirty()
        },
        editing,
        get focused() {
            return focused
        },
        set focused(f: boolean) {
            setFocused(f)
        },
        invalidate,
        setEditing,
        addSaveListener,
        removeSaveListener,
        discardChanges,
        save,
        versionKey: versionKey.valueOf(),
    }
}
