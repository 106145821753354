import { Type } from "../../reactor"
import { createContext, useContext } from "react"
import type {
    StudioFileInfo,
    StudioPropOptions,
    StudioPropWidget,
    StudioRefTypes,
    StudioTypeExtensions,
} from "../API/DocumentPageAPI"

export type DocumentContext = {
    doc: any
    loading?: boolean
    showAllProps: boolean

    /** The collection the current document corresponds to, if any. */
    collection?: string

    /** The primary key of current document, if any, in the context of `collection`. */
    primaryKey?: string

    /** Whether the user has permission to do at least some edits in this document context.
     *
     *  If true, the user can edit the entire document.
     *  If a list of strings, the user may edit the fields with those names.
     *  If false, all fields should be read-only.
     */
    canUpdate: boolean | string[]

    setShowAllProps(show: boolean): void

    /**
     * Refreshes the metadata for the document.
     *
     * This keeps local changes to the document, and updates the metadata
     * based on the local version of the document.
     *
     */
    refresh?(): Promise<void>

    /** The type of document being edited, if meaningful in the context.
     */
    type?: Type

    /** The mime-types of known files relevant in this context */
    files?: StudioFileInfo

    /** A dictionary with widget representations of all the referenced objects. */
    refs: StudioRefTypes

    /** A dictionary with available widget representations for parts of this document. */
    widgets?: StudioPropWidget

    /** A dictionary of type extensions for parts of this document. */
    typeExtensions?: StudioTypeExtensions

    options?: StudioPropOptions
}

export const DocumentContext = createContext<null | DocumentContext>(null)

export function useDocumentContext(): null | DocumentContext {
    return useContext(DocumentContext)
}
