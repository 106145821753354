import { saveAs } from "file-saver"
import React, { useCallback } from "react"
import { createContext, useContext } from "react"
import { useNavigate } from "../hooks/useNavigate"
import { ClientEffect } from "./client-effects/ClientEffect"
import { closeContextualModal, useContextualModal } from "../modal/Modal"
import { WidgetView } from "./WidgetView"
import type { Widget } from "./Widget"
import { FindWidgetByKey } from "./Helpers"
import { IsModal } from "./WidgetTypeHelpers"

type WidgetContext = {
    /** A dictionary holding state for the widget tree */
    state: any
    /** Replaces the entire state dictionary */
    setState(newState: any): Promise<void>

    /** Fetches the widget tree again from the source endpoint. */
    refresh(): Promise<void>

    /** Triggers an action associated with a given widget.
     *
     *  This typically corresponds to clicking buttons in the widget tree.
     *
     *  Returns the return value of the method, if any.
     */
    performAction(
        widgetKey: string,
        method?: string,
        args?: any[],
        /** Callback called after response was received from the backend, before effects are
         * executed. This allows e.g. busy indicators to be turned hidden before showing a blocking
         * message box. */
        onResponse?: () => void
    ): Promise<any>

    /** The root of the widget tree. Can be used to find widgets by key. */
    root: Widget
}

export const WidgetContext = createContext<WidgetContext>({
    state: {},
    setState() {
        throw new Error("No widget context")
    },
    refresh() {
        throw new Error("No widget context")
    },
    performAction() {
        throw new Error("No widget context")
    },
    root: null,
})

function useWidgetContext() {
    return useContext(WidgetContext)
}

export function useWidgetStateObject(key: string) {
    const { state, setState } = useWidgetContext()
    if (!(key in state)) {
        state[key] = {}
    }

    return [
        state[key],
        (value: any) => {
            return setState({ ...state, [key]: value })
        },
    ]
}

export function useWidgetState<T>(
    key: string,
    name: string,
    defaultValue: T
): [T, (value: T) => Promise<void>] {
    const [state, setState] = useWidgetStateObject(key)

    return [
        state[name] || defaultValue,
        async (value: T) => {
            if (value === defaultValue) {
                const newState = { ...state }
                delete newState[name]
                await setState(newState)
            } else {
                await setState({ ...state, [name]: value })
            }
        },
    ]
}

export function usePerformWidgetAction() {
    return useWidgetContext().performAction
}

export function useExecuteClientEffects() {
    const navigate = useNavigate()
    const { modal, showModal } = useContextualModal()

    return {
        modal,
        showModal,
        executeClientEffects: useCallback(
            (effects: ClientEffect[]) => {
                for (const effect of effects) {
                    switch (effect.type) {
                        case "Navigate":
                            navigate(effect.to.valueOf())
                            break
                        case "NavigateOpen":
                            window.open(effect.to.valueOf(), "")
                            break
                        case "SaveFile":
                            /**
                             * Directly sending a blob as the fileContent does not work
                             * The fileContent will always be a string and if the file type is
                             * pdf, it will be converted to Uint8Array to be transformed in blob
                             */
                            saveAs(
                                new Blob(
                                    [
                                        effect.contentType === "application/pdf"
                                            ? mapUriToUIntArray(effect.fileContent)
                                            : effect.fileContent,
                                    ],
                                    {
                                        type: effect.contentType ?? "text/plain;charset=utf-8",
                                    }
                                ),
                                effect.fileName
                            )
                            break
                        case "MessageBox":
                            alert(effect.message)
                            break
                        case "OpenModal":
                            void showModal(() => <WidgetKeyView widgetKey={effect.widgetKey} />)
                            break
                        case "CloseModal":
                            closeContextualModal()
                            break
                        default:
                            alert("Unimplemented client effect: " + JSON.stringify(effect))
                            break
                    }
                }
            },
            [navigate, saveAs]
        ),
    }
}

function WidgetKeyView(props: { widgetKey: string }) {
    const { root } = useWidgetContext()

    const widget = FindWidgetByKey(root, props.widgetKey)
    if (!widget) return <div>Widget not found: {props.widgetKey}</div>
    if (!IsModal(widget)) return <div>Widget is not modal: {props.widgetKey}</div>

    return <WidgetView value={widget.widget} />
}

function mapUriToUIntArray(uri: string) {
    const base64String = uri.split(",")[1]

    const binaryString = atob(base64String)

    return Uint8Array.from([...binaryString].map((char) => char.charCodeAt(0)))
}
