import { CSSProperties, useMemo, useState } from "react"
import { css } from "@emotion/react"
import { EditableText } from "../../../packages/editing/EditableText"
import { useParam } from "../../../packages/ssr/useParam"
import { WidgetAPIView } from "../../../packages/widgets/WidgetAPIView"
import { Uuid } from "../../../reactor/Types/Primitives"
import {
    DenominatorUnit,
    GetOfferingReportDto,
    GetOfferingReportOfferingExecutiveSummaryDto,
    GetOfferingReportOfferingProcessQualitativeOverviewDto,
    GetOfferingReportOfferingProjectionSummaryDto,
    GetOfferingReportTextForAnalysisParameters1Dto,
    GetOfferingReportTextForExecutiveSummaryProjections1Dto,
    ProcessItem,
    useOfferingReport,
    useUserPrivileges,
} from "./client"
import { Chart } from "chart.js"
import { colors, hideOnPrint, hideOnScreen, printCss } from "./styles"
import { Image, ImageToUrl } from "../../../reactor/Types/File"

import { WidgetView } from "../../../packages/widgets/WidgetView"
import { capitalizeFirstLetter, lowercaseFirstLetter } from "../../../reactor/Helpers"
import { ImpactPriority, ImpactScore, Offering, type ProcessItemBenchmark } from "../Offering"
import { useEditableContext } from "../../../packages/editing/EditableContext"
import { Footer } from "./sections/Footer"
import { useChangeNotifications } from "../../../reactor/Web"
import { ColorStyles } from "../../../packages/ui"
import { HeaderSection } from "./sections/Header"
import { MarkdownView } from "../../../packages/markdown-edit/MarkdownView"
import Logo from "./assets/Logo"

Chart.defaults.font.family = "Jost"
Chart.defaults.font.size = 16

export function OfferingReportView() {
    const reportId = useParam("offeringId") as any as Uuid<"Offering">
    const { data: report, refresh } = useOfferingReport(reportId)
    const { data: privileges } = useUserPrivileges()
    const [impactCategory, setImpactCategory] = useState<string>("")

    useChangeNotifications("Offerings", refresh, !!privileges?.canEditWebsite)

    if (!report) return <></>

    const {
        offering,
        offeringUnit,
        impactCategories,
        firstPublished,
        lastModified,
        textForExecutiveSummaryProjections,
    } = report

    if (!impactCategory && impactCategories[0]?.id) {
        setImpactCategory(impactCategories[0]?.id.valueOf())
    }

    return (
        <table>
            {/* Wrap in table to achieve a top right logo in every page for print version */}
            <thead css={css(hideOnScreen)}>
                <tr>
                    <td align="right">
                        <Logo color="darkGreen" width={48} />
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <div
                            css={css(
                                {
                                    canvas: {
                                        // Fixes streched chart canvas on print
                                        width: "100% !important",
                                        height: "auto !important",
                                    },
                                },
                                printCss({ display: "block", fontSize: 14, p: { fontSize: 14 } })
                            )}
                        >
                            <div
                                css={css([
                                    hideOnScreen,
                                    {
                                        height: 90,
                                        display: "flex",
                                        justifyContent: "right",
                                        alignItems: "center",
                                    },
                                ])}
                            >
                                <div
                                    css={css(
                                        printCss({
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            left: 0,
                                            height: 90,
                                        })
                                    )}
                                >
                                    <Logo
                                        color="white"
                                        width={48}
                                        css={css({
                                            zIndex: 2,
                                            position: "absolute",
                                            right: 20,
                                            top: 20,
                                        })}
                                    />
                                    <svg
                                        css={css({
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            left: 0,
                                            zIndex: 1,
                                        })}
                                        width="100%"
                                        height="100%"
                                        version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 10 10"
                                        preserveAspectRatio="none"
                                    >
                                        <rect
                                            x="0"
                                            y="0"
                                            width="10"
                                            height="10"
                                            fill={colors.darkGreen}
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    position: "relative",
                                }}
                            >
                                <div css={css({ width: "100%" }, hideOnPrint)}>
                                    <HeaderSection size="compact" />
                                </div>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div
                                        style={{
                                            position: "absolute",
                                            right: 32,
                                            marginTop: 32,
                                            fontSize: 16,
                                            fontWeight: "normal",
                                            color: "#888",
                                        }}
                                    >
                                        <div style={{ textAlign: "right" }}>
                                            First published: {firstPublished}
                                        </div>
                                        <div style={{ textAlign: "right" }}>
                                            Last modified: {lastModified}
                                        </div>
                                    </div>
                                </div>

                                <div
                                    style={{ width: "100%", maxWidth: 1280 }}
                                    css={css(
                                        printCss({
                                            "> div": {
                                                marginLeft: "0 !important",
                                                marginRight: "0 !important",
                                            },
                                        })
                                    )}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            color: colors.darkGreen,
                                        }}
                                    >
                                        <div style={{ marginTop: 32, fontSize: 20 }}>
                                            {offering.usage}
                                        </div>
                                        <div
                                            style={{
                                                marginTop: 8,
                                                marginBottom: 8,
                                                fontSize: 36,
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {offering.name}
                                        </div>
                                        <div style={{ fontSize: 24 }}>{offering.secondaryName}</div>
                                        <div style={{ margin: 20, fontSize: 20 }}>
                                            {offering.typeOfProductOrService &&
                                                offering.typeOfProductOrService + " Solution"}
                                        </div>
                                        <div
                                            style={{
                                                marginTop: 32,
                                                marginBottom: 5,
                                                fontSize: 16,
                                                fontWeight: "normal",
                                                color: "#888",
                                            }}
                                        >
                                            <a
                                                onClick={(e) => {
                                                    if (report.account?.slug) {
                                                        window.open(
                                                            `/accounts/${report.account?.slug?.toLocaleLowerCase()}`
                                                        )
                                                    } else {
                                                        window.open(
                                                            `/accounts/${report.account?.id?.toLocaleLowerCase()}`
                                                        )
                                                    }
                                                }}
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <EditableText
                                                    obj={report.offering}
                                                    prop="attributionPrefix"
                                                    defaultText="A solution by"
                                                />

                                                <div
                                                    style={{
                                                        cursor: "pointer",
                                                        marginLeft: 4,
                                                        textDecoration: "underline",
                                                        color: "#61A391",
                                                        fontWeight: "500",
                                                    }}
                                                >
                                                    {report.account?.name}
                                                </div>
                                            </a>
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            margin: 32,
                                        }}
                                    >
                                        {offering.image?.valueOf() && (
                                            <img
                                                src={ImageToUrl(offering.image?.valueOf())}
                                                alt="image"
                                                style={{
                                                    flex: 1,
                                                    flexDirection: "column",
                                                    objectFit: "cover",
                                                    padding: 32,
                                                    maxWidth: "50%",
                                                }}
                                                css={css(printCss({ display: "none" }))}
                                            />
                                        )}
                                        <div
                                            style={{
                                                flex: 1,
                                                flexDirection: "column",
                                                padding: 32,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: 20,
                                                    color: colors.darkGreen,
                                                    marginBottom: 16,
                                                }}
                                            >
                                                Overview
                                            </div>
                                            {offering.overview && (
                                                <EditableText
                                                    obj={offering}
                                                    isMarkdown={true}
                                                    prop="overview"
                                                    defaultText="(empty)"
                                                    style={{}}
                                                    placeholder="Overview goes here"
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <AnalasysParameters
                                        offering={offering}
                                        offeringUnit={offeringUnit}
                                        textForAnalysisParameters={report.textForAnalysisParameters}
                                    />

                                    {offering.executiveSummary && (
                                        <ExecutiveSummary
                                            offeringId={offering.id}
                                            summary={offering.executiveSummary}
                                            projectionSummary={
                                                report.hideProjectionsSummary
                                                    ? undefined
                                                    : offering.projectionSummary
                                            }
                                            textForExecutiveSummaryProjections={
                                                textForExecutiveSummaryProjections
                                            }
                                        />
                                    )}

                                    <div>
                                        <div
                                            style={{
                                                margin: "0 32px",
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                            css={css(
                                                printCss({
                                                    marginLeft: "0 !important",
                                                    marginRight: "0 !important",
                                                })
                                            )}
                                        >
                                            <div style={header2}>Process Overview</div>
                                            {ReportSettingsTexts(
                                                report.textForProcessOverview,
                                                report.processOverviewDiagram
                                            )}
                                            <div
                                                style={{ marginLeft: 16, marginRight: 16 }}
                                                css={css`
                                                    table {
                                                        break-before: avoid;
                                                        break-inside: avoid !important;
                                                    }
                                                `}
                                            >
                                                <WidgetAPIView
                                                    param={offering.id.valueOf()}
                                                    name="OfferingReportProcessOverview"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        css={css(
                                            {
                                                margin: 32,
                                                display: "flex",
                                                flexDirection: "column",
                                            },
                                            printCss({ display: "block" })
                                        )}
                                    >
                                        <div style={header2} css={css({ breakAfter: "avoid" })}>
                                            Scope Allocation Analysis
                                        </div>
                                        {ReportSettingsTexts(
                                            report.textForScopeAllocationAnalysis,
                                            report.scopeAllocationDiagram
                                        )}

                                        {report?.impactCategories?.length > 1 && (
                                            <div
                                                style={{
                                                    marginLeft: 32,
                                                    marginTop: 32,
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                <div style={{ marginRight: 16 }}>Impact factor</div>
                                                <select
                                                    value={impactCategory}
                                                    onChange={(e) =>
                                                        setImpactCategory(e.target.value)
                                                    }
                                                    style={{
                                                        padding: 8,
                                                        borderRadius: 8,
                                                        borderColor: "#ccc",
                                                    }}
                                                >
                                                    {report.impactCategories.map((c) => (
                                                        <option
                                                            key={c.id.valueOf()}
                                                            value={c.id.valueOf()}
                                                        >
                                                            {c.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        )}
                                        <div css={css(printCss({ display: "none" }))}>
                                            {ProcessColumnHeader()}
                                        </div>
                                        <div style={{ flexDirection: "row", alignItems: "center" }}>
                                            <div
                                                style={{
                                                    flex: 1,
                                                    height: 2,
                                                    backgroundColor: colors.darkGreen,
                                                }}
                                            />
                                        </div>
                                        {impactCategory &&
                                            offering.process.map((p, i) => {
                                                return (
                                                    <ProcessItemView
                                                        key={p.name}
                                                        index={i}
                                                        processItem={p}
                                                        unit={report.functionalUnit}
                                                        report={report}
                                                        setImpactCategory={setImpactCategory}
                                                        impactCategory={impactCategory}
                                                        isLoggedIn={privileges?.isLoggedIn}
                                                    />
                                                )
                                            })}
                                        <div
                                            style={{
                                                flexDirection: "row",
                                                alignItems: "center",
                                                marginTop: 32,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    flex: 1,
                                                    height: 2,
                                                    backgroundColor: colors.darkGreen,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            margin: 32,
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <div style={header2}>Impact Aggregation</div>
                                        <div
                                            style={{
                                                marginLeft: 32,
                                                marginRight: 32,
                                                maxWidth: 1280,
                                            }}
                                        >
                                            <div style={header3}>
                                                {
                                                    report.textForScopeAggregation
                                                        ?.outliningImpactActionHeader
                                                }
                                            </div>
                                            {report.textForScopeAggregation && (
                                                <div>
                                                    <MarkdownView
                                                        value={
                                                            report.textForScopeAggregation
                                                                .outliningImpactActionText
                                                        }
                                                    />
                                                </div>
                                            )}
                                            <div style={{ marginTop: 32 }}>
                                                <WidgetAPIView
                                                    param={offering.id.valueOf()}
                                                    name="ImpactByScopeProcessItemsChart"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {!report.hideProjectionsSection && (
                                        <div
                                            style={{
                                                margin: 32,
                                                display: "flex",
                                                flexDirection: "column",
                                                maxWidth: 1280,
                                            }}
                                        >
                                            <div style={header2}>Projections</div>
                                            {ReportSettingsTexts(
                                                report.textForProjection,
                                                report.projectionDiagram
                                            )}
                                            <div
                                                style={{ marginLeft: 16, marginRight: 16 }}
                                                css={css(
                                                    printCss({
                                                        // Targets the charts to make them full width on print
                                                        "> div > div": {
                                                            display: "block !important",
                                                        },
                                                    }),
                                                    printCss(css`
                                                        table {
                                                            break-inside: avoid !important;
                                                            break-before: avoid !important;
                                                        }
                                                    `)
                                                )}
                                            >
                                                <WidgetAPIView
                                                    param={offering.id.valueOf()}
                                                    name="ProductionProjectionTab"
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

const lightYellowBg = ColorStyles.yellow[100]
const lightOrangeBg = ColorStyles.orange[50]
const lightGreenBg = ColorStyles.green[100]
const lightBlueBg = ColorStyles.blue[100]

const header1 = { fontSize: 36, marginTop: 32, fontWeight: "bold" }
const header2: CSSProperties = {
    marginTop: 0,
    marginBottom: 16,
    fontSize: 28,
    alignSelf: "center",
    textAlign: "center",
    color: colors.darkGreen,
    breakBefore: "page",
    breakAfter: "avoid",
}
const header3: CSSProperties = {
    marginTop: 24,
    marginBottom: 8,
    fontSize: 20,
    fontWeight: "bold",
    color: colors.darkGreen,
    breakAfter: "avoid",
}

function AnalasysParameters({
    offering,
    offeringUnit,
    textForAnalysisParameters,
}: {
    offering: Offering
    offeringUnit: DenominatorUnit
    textForAnalysisParameters?: GetOfferingReportTextForAnalysisParameters1Dto
}) {
    const [expanded, setExpanded] = useState(false)

    return (
        <div
            css={css({
                margin: "0 32px",
                display: "flex",
                flexDirection: "column",
            })}
        >
            <div style={{ ...header2, cursor: "pointer" }} onClick={() => setExpanded(!expanded)}>
                Analysis Parameters
            </div>
            {expanded ? (
                <>
                    <div
                        style={{
                            marginLeft: 32,
                            marginRight: 32,
                            maxWidth: 1280,
                        }}
                    >
                        <div style={header3}>
                            {textForAnalysisParameters?.outliningImpactActionHeader}
                        </div>
                        {textForAnalysisParameters && (
                            <MarkdownView
                                value={textForAnalysisParameters.outliningImpactActionText}
                            />
                        )}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: 32,
                            marginTop: -30,
                        }}
                    >
                        <div
                            style={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                breakInside: "avoid",
                            }}
                        >
                            <div style={header3}>Functional Unit</div>

                            <WidgetView
                                value={{
                                    type: "Item",
                                    icon: "balance-scale",
                                    primary:
                                        capitalizeFirstLetter(offeringUnit.name) +
                                        " of " +
                                        lowercaseFirstLetter(offering.name),
                                }}
                            />
                            <div style={{ marginTop: 32 }}>
                                <EditableText
                                    isMarkdown={true}
                                    obj={offering}
                                    prop="unitDescription"
                                    defaultText="(empty)"
                                />
                            </div>
                            <b>Reference flow</b>

                            <EditableText
                                isMarkdown={true}
                                obj={offering}
                                prop="referenceFlow"
                                defaultText="(empty)"
                            />

                            <div style={header3}>Goal and scope</div>
                            <EditableText
                                isMarkdown={true}
                                obj={offering}
                                prop="goal"
                                defaultText="(empty)"
                            />
                            <b>Reason for study</b>
                            <EditableText
                                isMarkdown={true}
                                obj={offering}
                                prop="reasonForStudy"
                                defaultText="(empty)"
                            />
                            <b>Audience</b>
                            <EditableText
                                isMarkdown={true}
                                obj={offering}
                                prop="audience"
                                defaultText="(empty)"
                            />
                        </div>
                        <div style={{ width: 64 }} />
                        <div
                            style={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <div style={header3}>System Boundary</div>
                            <EditableText
                                isMarkdown={true}
                                obj={offering}
                                prop="systemBoundaryDescription"
                                defaultText="(empty)"
                            />

                            <b>Technology Coverage</b>
                            <EditableText
                                isMarkdown={true}
                                obj={offering}
                                prop="technologyCoverage"
                                defaultText="(empty)"
                            />
                            <b>Geographical boundary</b>
                            <EditableText
                                isMarkdown={true}
                                obj={offering}
                                prop="geographicalBoundary"
                                defaultText="(empty)"
                            />
                            <b>Exclusions</b>
                            <EditableText
                                isMarkdown={true}
                                obj={offering}
                                prop="exclusions"
                                defaultText="(empty)"
                            />
                            <b>Temporal coverage</b>
                            <EditableText
                                isMarkdown={true}
                                obj={offering}
                                prop="temporalCoverage"
                                defaultText="(empty)"
                            />

                            <div style={header3}>Lifetime</div>
                            <EditableText
                                isMarkdown={true}
                                obj={offering}
                                prop="lifetimeDescription"
                                defaultText="(empty)"
                            />
                        </div>
                    </div>
                </>
            ) : (
                <div
                    onClick={() => setExpanded(!expanded)}
                    style={{
                        display: "flex",
                        justifyContent: "space-around",
                        borderBottom: "1px solid",
                        margin: "0 32px",
                        paddingBottom: "32px",
                    }}
                >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={header3}>Functional Unit</div>
                        <WidgetView
                            value={{
                                type: "Item",
                                icon: "balance-scale",
                                primary:
                                    capitalizeFirstLetter(offeringUnit.name) +
                                    " of " +
                                    lowercaseFirstLetter(offering.name),
                            }}
                        />
                    </div>
                    {offering.benchmarks.map((b) => (
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={header3}>Benchmark</div>
                            <WidgetView
                                value={{
                                    type: "Item",
                                    icon: "balance-scale",
                                    primary: capitalizeFirstLetter(b.name),
                                }}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

function ExecutiveSummary({
    offeringId,
    summary,
    projectionSummary,
    textForExecutiveSummaryProjections,
}: {
    offeringId: Uuid<"Offering">
    summary: GetOfferingReportOfferingExecutiveSummaryDto
    projectionSummary?: GetOfferingReportOfferingProjectionSummaryDto
    textForExecutiveSummaryProjections?: GetOfferingReportTextForExecutiveSummaryProjections1Dto
}) {
    return (
        <div style={{ margin: 32, display: "flex", flexDirection: "column" }}>
            <div style={header2}>Executive Summary</div>

            <div
                css={css(
                    { display: "flex", flexDirection: "row", padding: "0 32px" },
                    printCss({ display: "block", fontSize: 12, p: { fontSize: 12 } })
                )}
            >
                <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                    <div style={header3}>Key revelations</div>
                    <EditableText
                        isMarkdown={true}
                        obj={summary}
                        prop="keyRevelations"
                        defaultText="(empty)"
                    />

                    <div style={header3}>Insights to Impact Strategy</div>
                    <EditableText
                        obj={summary}
                        isMarkdown={true}
                        prop="insightsToImpactStrategy"
                        defaultText="(empty)"
                    />

                    <div style={header3}>Offering Projections</div>
                    <EditableText
                        obj={summary}
                        isMarkdown={true}
                        prop="offeringProjections"
                        defaultText="(empty)"
                    />
                </div>
                <div style={{ width: 64 }} />

                <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                    <div style={header3}>Potential Challenges</div>
                    <EditableText
                        obj={summary}
                        isMarkdown={true}
                        prop="potentialChallenges"
                        defaultText="(empty)"
                    />

                    <div style={header3}>Possible Rebounds</div>
                    <EditableText
                        obj={summary}
                        isMarkdown={true}
                        prop="possibleRebounds"
                        defaultText="(empty)"
                    />

                    <div style={header3}>Climate Value Proposition</div>
                    <EditableText
                        obj={summary}
                        isMarkdown={true}
                        prop="climateValueProposition"
                        defaultText="(empty)"
                    />
                </div>
            </div>
            {projectionSummary && (
                <div
                    style={{
                        paddingLeft: 32,
                        paddingRight: 32,
                        paddingBottom: 32,
                        borderRadius: 8,
                    }}
                >
                    <Tabs
                        tabs={{
                            Projections: (
                                <>
                                    <div
                                        css={css(
                                            { display: "flex", flexDirection: "row" },
                                            printCss({ display: "block" })
                                        )}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flex: 1,
                                                margin: 16,
                                                breakInside: "avoid",
                                            }}
                                        >
                                            <WidgetAPIView
                                                name="ProductionProjectionChart"
                                                param={offeringId.valueOf()}
                                            />
                                        </div>
                                    </div>
                                </>
                            ),
                            "Annual Impact": (
                                <>
                                    <div
                                        css={css(
                                            { display: "flex", flexDirection: "row" },
                                            printCss({ display: "block" })
                                        )}
                                    >
                                        <div style={{ flex: 2, marginRight: 32, marginLeft: 16 }}>
                                            <div style={header3}>
                                                {
                                                    textForExecutiveSummaryProjections
                                                        ?.annualProjections.header
                                                }
                                            </div>
                                            <div style={{ marginBottom: 16 }}>
                                                {
                                                    textForExecutiveSummaryProjections
                                                        ?.annualProjections.text
                                                }
                                            </div>
                                            <EditableText
                                                obj={projectionSummary}
                                                isMarkdown={true}
                                                prop="annualEmissions"
                                                defaultText="(empty)"
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flex: 3,
                                                breakInside: "avoid",
                                            }}
                                            css={css({
                                                canvas: {
                                                    // Fixes streched chart canvas on print
                                                    width: "100% !important",
                                                    height: "auto !important",
                                                },
                                            })}
                                        >
                                            <WidgetAPIView
                                                name="ImpactAggregationChart"
                                                param={offeringId.valueOf()}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ width: "100%", breakInside: "avoid" }}>
                                        <WidgetAPIView
                                            name="ImpactAggregationTable"
                                            param={offeringId.valueOf()}
                                        />
                                    </div>
                                </>
                            ),
                            "Cumulative Impact": (
                                <>
                                    <div
                                        css={css(
                                            { display: "flex", flexDirection: "row" },
                                            printCss({ display: "block" })
                                        )}
                                    >
                                        <div style={{ flex: 2, marginRight: 32, marginLeft: 16 }}>
                                            <div style={header3}>
                                                {
                                                    textForExecutiveSummaryProjections
                                                        ?.cumulativeProjections.header
                                                }
                                            </div>

                                            <div style={{ marginBottom: 16 }}>
                                                {
                                                    textForExecutiveSummaryProjections
                                                        ?.cumulativeProjections.text
                                                }
                                            </div>
                                            <EditableText
                                                obj={projectionSummary}
                                                isMarkdown={true}
                                                prop="cumulativeEmissions"
                                                defaultText="(empty)"
                                            />
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flex: 3,
                                                breakInside: "avoid",
                                            }}
                                        >
                                            <WidgetAPIView
                                                name="CumulativeImpactChart"
                                                param={offeringId.valueOf()}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ width: "100%", breakInside: "avoid" }}>
                                        <WidgetAPIView
                                            name="CumulativeImpactTable"
                                            param={offeringId.valueOf()}
                                        />
                                    </div>
                                </>
                            ),

                            "Reaching the ClimatePoint": (
                                <>
                                    <div
                                        css={css(
                                            { display: "flex", flexDirection: "row" },
                                            printCss({ display: "block" })
                                        )}
                                    >
                                        <div style={{ flex: 2, marginRight: 32, marginLeft: 16 }}>
                                            <div style={header3}>
                                                {
                                                    textForExecutiveSummaryProjections
                                                        ?.reachingTheClimatePoint.header
                                                }
                                            </div>

                                            <div style={{ marginBottom: 16 }}>
                                                {
                                                    textForExecutiveSummaryProjections
                                                        ?.reachingTheClimatePoint.text
                                                }
                                            </div>

                                            <EditableText
                                                obj={projectionSummary}
                                                isMarkdown={true}
                                                prop="reachingTheClimatePoint"
                                                defaultText="(empty)"
                                            />
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flex: 3,
                                                breakInside: "avoid",
                                            }}
                                        >
                                            <WidgetAPIView
                                                name="AmortizedImpactChart"
                                                param={offeringId.valueOf()}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ width: "100%", breakInside: "avoid" }}>
                                        <WidgetAPIView
                                            name="AmortizedImpactTable"
                                            param={offeringId.valueOf()}
                                        />
                                    </div>
                                </>
                            ),
                        }}
                    />
                </div>
            )}
        </div>
    )
}

export function Tabs({ tabs }: { tabs: { [title: string]: JSX.Element } }) {
    const [current, setCurrent] = useState(Object.keys(tabs)[0])

    return (
        <>
            <div style={{ marginTop: 64 }} css={css(hideOnPrint)}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        marginBottom: 32,
                    }}
                >
                    {Object.keys(tabs).map((title, i) => (
                        <div
                            key={title}
                            style={{
                                flex: 1,
                                textAlign: "center",
                                padding: 8,
                                backgroundColor:
                                    current === title
                                        ? ColorStyles.primary[700]
                                        : ColorStyles.primary[100],
                                color: current === title ? "white" : ColorStyles.primary[800],
                                borderRadius: 8,
                                marginLeft: i > 0 ? 16 : 0,
                                userSelect: "none",
                                cursor: "pointer",
                            }}
                            onClick={() => setCurrent(title)}
                        >
                            {title}
                        </div>
                    ))}
                </div>
                {tabs[current]}
            </div>
            {/** A simpliefied rendering for print only */}
            <div css={css(hideOnScreen)}>
                {Object.entries(tabs).map(([title, tab]) => (
                    <div
                        key={title}
                        css={css({ marginBottom: 50, ":last-child": { marginBottom: 0 } })}
                    >
                        <div>{tab}</div>
                    </div>
                ))}
            </div>
        </>
    )
}

export function impactScoreColor(s: ImpactScore) {
    switch (s) {
        case "Aligned":
            return colors.primaryGreen
        case "Negative":
            return colors.red
        case "Potential":
            return colors.orange
        case "Rebound":
            return colors.orange
        case "None":
            return colors.green
    }
}
export function impactPriorityColor(s: ImpactPriority) {
    switch (s) {
        case "High":
            return colors.red
        case "Medium":
            return colors.orange
        case "Low":
            return colors.primaryGreen
        case "None":
            return colors.green
    }
}

function ReportSettingsTexts(textObject?: any, diagram?: Image) {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            {diagram && (
                <img
                    src={ImageToUrl(diagram)}
                    alt="image"
                    style={{
                        marginTop: 32,
                        marginBottom: 32,
                        objectPosition: "center",
                        height: 300,
                        objectFit: "contain",
                    }}
                />
            )}
            <div
                css={css(
                    {
                        display: "flex",
                        flexDirection: "row",
                        padding: 32,
                        marginTop: -30,
                    },
                    printCss({ display: "block" })
                )}
            >
                <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                    <div style={header3}>{textObject?.backgroundInformationHeader}</div>
                    {textObject && <MarkdownView value={textObject.backgroundInformationText} />}
                </div>
                <div style={{ width: 64 }} />
                <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                    <div style={header3}>{textObject?.outliningImpactActionHeader}</div>
                    {textObject && <MarkdownView value={textObject.outliningImpactActionText} />}
                </div>
            </div>
        </div>
    )
}

function ProcessColumnHeader() {
    return (
        <div
            style={{
                marginTop: 32,
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: 8,
                    height: 64,
                    cursor: "pointer",
                }}
            >
                <div
                    style={{
                        height: "100%",
                        width: 64,
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "black",
                        fontSize: 26,
                    }}
                >
                    <div
                        style={{
                            color: colors.darkGreen,
                            fontWeight: "bold",
                        }}
                    >
                        #
                    </div>
                </div>
                <div
                    style={{
                        fontSize: 24,
                        padding: 16,
                        color: colors.darkGreen,
                        flex: 3,
                        fontWeight: "bold",
                    }}
                >
                    Process item
                </div>
                <div
                    style={{
                        flex: 1.5,
                        fontWeight: "bold",
                        fontSize: 24,
                        color: colors.darkGreen,
                    }}
                >
                    {"Scope"}
                </div>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        fontWeight: "bold",
                        fontSize: 24,
                        color: colors.darkGreen,
                    }}
                >
                    Score
                </div>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginRight: 60,
                        fontWeight: "bold",
                        fontSize: 24,
                        color: colors.darkGreen,
                    }}
                >
                    Priority
                </div>
            </div>
        </div>
    )
}

function ProcessItemView({
    processItem: pi,
    impactCategory,
    report,
    index,
    setImpactCategory,
    isLoggedIn,
}: {
    processItem: ProcessItem
    impactCategory: string
    report: GetOfferingReportDto
    index: number
    setImpactCategory: React.Dispatch<React.SetStateAction<string>>
    unit: DenominatorUnit | undefined
    isLoggedIn: boolean | undefined
}) {
    const piName = pi.shortName ?? pi.name
    const [expanded, setExpanded] = useState(false)
    const [benchmarkName, setbenchMarkName] = useState(piName)
    const { editing } = useEditableContext()

    const benchmark = useMemo(
        () => pi.benchmark.find((b) => b.name === benchmarkName),
        [benchmarkName]
    )

    function renderScore(score: ImpactScore | ImpactPriority, type: "score" | "priority") {
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <div
                    style={{
                        width: 9,
                        height: 9,
                        borderRadius: 6,
                        backgroundColor:
                            type === "score"
                                ? impactScoreColor((score as ImpactScore) || "None")
                                : impactPriorityColor((score as ImpactPriority) || "None"),
                        marginRight: 8,
                    }}
                />
                {score || "None"}
            </div>
        )
    }

    return (
        <div
            style={{
                marginTop: 32,
                display: "flex",
                flexDirection: "column",
                breakInside: "avoid",
            }}
        >
            <div
                onClick={() => setExpanded(!expanded)}
                css={css(
                    {
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: "#EEEFF1",
                        alignItems: "center",
                        borderRadius: 8,
                        height: 64,
                        cursor: "pointer",
                    },
                    printCss({
                        flexDirection: "column",
                        alignItems: "flex-start",
                        height: "auto",
                        margin: "0 32px",
                        backgroundColor: "transparent",
                    })
                )}
            >
                <div
                    css={css(
                        {
                            backgroundColor:
                                pi.parentScope === "Scope 1"
                                    ? colors.peach
                                    : pi.parentScope === "Scope 2"
                                      ? colors.darkGreen
                                      : colors.primaryGreen,
                            height: "100%",
                            width: 64,
                            borderTopLeftRadius: 8,
                            borderBottomLeftRadius: 8,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white",
                            fontSize: 26,
                        },
                        printCss({
                            display: "none",
                        })
                    )}
                >
                    {index + 1}
                </div>
                <EditableText
                    obj={pi}
                    prop="name"
                    css={css(
                        { fontSize: 26, padding: 16, color: colors.darkGreen, flex: 3 },
                        printCss({
                            fontSize: 20,
                            padding: 0,
                            fontWeight: "bold",
                            ":before": {
                                content: `"${index + 1}. "`,
                            },
                        })
                    )}
                />
                <div
                    style={{ flex: 1.5 }}
                    css={css(printCss({ ":before": { content: '"Scope: "' } }))}
                >
                    {pi.recursiveScopeAnalysis
                        ? "Multiple Scopes"
                        : `${pi.parentScope} ${pi.childScope}`}
                </div>

                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                    css={css(printCss({ ":before": { content: '"Score: "' } }))}
                >
                    {renderScore(pi.qualitativeOverview.impactScore, "score")}
                </div>

                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                    css={css(printCss({ ":before": { content: '"Priority: "' } }))}
                >
                    {renderScore(pi.qualitativeOverview.priority, "priority")}
                </div>
                <i
                    className={`fas fa-chevron-${expanded ? "up" : "down"}`}
                    style={{
                        flex: 0.15,
                        paddingRight: 32,
                        textAlign: "center",
                    }}
                    css={css(hideOnPrint)}
                />
            </div>
            <div css={css(!expanded && hideOnScreen)}>
                <>
                    <QualitativeOverviewImpact
                        qualitativeOverview={pi.qualitativeOverview}
                        editing={editing}
                    />
                    {isLoggedIn ? (
                        pi.quantitativeAnalysisVisible && (
                            <>
                                <div style={{ marginTop: 48, fontSize: 20 }}>
                                    Quantitative Overview
                                </div>
                                <div style={{ marginTop: 16, padding: 32 }}>
                                    <div style={{ fontWeight: "bold" }}>
                                        Quantification Analysis
                                    </div>
                                    <EditableText
                                        obj={pi}
                                        prop="quantificationAnalysis"
                                        defaultText="Quantification analysis goes here"
                                        isMarkdown={true}
                                        style={{
                                            marginTop: 16,
                                        }}
                                    />
                                </div>
                                {editing || pi.additionalInsights ? (
                                    <div style={{ marginTop: 16, padding: 32 }}>
                                        <div style={{ fontWeight: "bold" }}>
                                            Additional Insights
                                        </div>

                                        <EditableText
                                            obj={pi}
                                            prop="additionalInsights"
                                            defaultText="Additional insights go here"
                                            isMarkdown={true}
                                            style={{
                                                marginTop: 16,
                                            }}
                                        />
                                    </div>
                                ) : undefined}
                                <div style={{ marginTop: 16, marginBottom: 48 }}>
                                    <WidgetAPIView
                                        name="SubprocessImpact"
                                        param={`${report.offering.id}:${pi.id.valueOf()}:${impactCategory}`}
                                    />
                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginBottom: 48,
                                    }}
                                >
                                    <div
                                        style={{
                                            marginLeft: 32,
                                            marginTop: 32,
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        <div style={{ marginRight: 16 }}>Impact factor</div>
                                        <select
                                            value={impactCategory}
                                            onChange={(e) => setImpactCategory(e.target.value)}
                                            style={{
                                                padding: 8,
                                                borderRadius: 8,
                                                borderColor: "#ccc",
                                            }}
                                        >
                                            {report.impactCategories.map((c) => (
                                                <option key={c.id.valueOf()} value={c.id.valueOf()}>
                                                    {c.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                {pi.displaySubProcess && pi.offering ? (
                                    <>
                                        <div
                                            style={{
                                                backgroundColor: benchmark
                                                    ? lightYellowBg
                                                    : lightOrangeBg,
                                                padding: 32,
                                                marginTop: 16,
                                                borderRadius: 8,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    fontWeight: "bold",
                                                    marginBottom: 16,
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <span>Quantified sub-process</span>
                                                <select
                                                    value={benchmarkName}
                                                    onChange={(e) =>
                                                        setbenchMarkName(e.target.value)
                                                    }
                                                    style={{
                                                        padding: 8,
                                                        borderRadius: 8,
                                                        borderColor: "#ccc",
                                                    }}
                                                >
                                                    <option key={piName} value={piName}>
                                                        {piName}
                                                    </option>
                                                    {pi.benchmark.map(
                                                        (c) =>
                                                            c.offering && (
                                                                <option key={c.name} value={c.name}>
                                                                    {c.name}
                                                                </option>
                                                            )
                                                    )}
                                                </select>
                                            </div>
                                            <EditableText
                                                obj={pi}
                                                isMarkdown={true}
                                                prop="subProcessNotes"
                                                placeholder="Notes go here"
                                            />
                                            <WidgetAPIView
                                                name="ProcessImpactBarChart"
                                                param={`${report.offering.id.valueOf()}:${pi.id.valueOf()}:${impactCategory}:${benchmarkName}`}
                                            />
                                        </div>
                                    </>
                                ) : undefined}

                                <PathwayGraphs
                                    pi={benchmark || pi}
                                    impactCategory={impactCategory}
                                />
                            </>
                        )
                    ) : (
                        <div
                            style={{
                                backgroundColor: lightBlueBg,
                                padding: 32,
                                marginTop: 16,
                                borderRadius: 8,
                            }}
                        >
                            <div style={{ fontWeight: "bold" }}>
                                User account login required to view quantification analysis.
                            </div>
                        </div>
                    )}
                </>
            </div>
        </div>
    )
}

type QualitativeOverviewImpactProps = {
    qualitativeOverview: GetOfferingReportOfferingProcessQualitativeOverviewDto
    editing: boolean
}

function QualitativeOverviewImpact({
    qualitativeOverview,
    editing,
}: QualitativeOverviewImpactProps) {
    return (
        <>
            <div
                css={css(
                    {
                        backgroundColor: lightYellowBg,
                        padding: 16,
                        margin: "16px 0",
                        borderRadius: 8,
                    },
                    printCss({
                        padding: 0,
                        backgroundColor: "transparent",
                        paddingLeft: 32,
                        paddingRight: 32,
                    })
                )}
            >
                <div style={{ fontWeight: "bold" }}>Process Description</div>
                <EditableText
                    obj={qualitativeOverview}
                    isMarkdown={true}
                    prop="processDescription"
                    defaultText="Process Description goes here"
                    style={{ fontStyle: "italic", marginTop: 8 }}
                />
            </div>

            {editing || qualitativeOverview.methodology ? (
                <>
                    <div
                        style={{ marginTop: 48, fontSize: 20 }}
                        css={css(printCss({ paddingLeft: 32, paddingRight: 32 }))}
                    >
                        Methodology
                    </div>

                    <EditableText
                        obj={qualitativeOverview}
                        isMarkdown={true}
                        prop="methodology"
                        defaultText="Methodology goes here"
                        style={{
                            padding: 32,
                            marginTop: 16,
                        }}
                    />
                </>
            ) : undefined}
        </>
    )
}

type PathwayGraphsProps = {
    pi: ProcessItem | ProcessItemBenchmark
    impactCategory: string
}

function PathwayGraphs({ pi, impactCategory }: PathwayGraphsProps) {
    return (
        <>
            {("showBaselineImpactPathway" in pi ? pi.showBaselineImpactPathway : true) &&
                pi.baselineImpactPathway && (
                    <div
                        style={{
                            backgroundColor: lightBlueBg,
                            padding: 32,
                            marginTop: 16,
                            borderRadius: 8,
                            breakInside: "avoid",
                        }}
                    >
                        <div style={{ fontWeight: "bold", marginBottom: 16 }}>
                            Baseline scenario (%)
                        </div>
                        {"baselineImpactPathwayDescription" in pi && (
                            <EditableText
                                obj={pi}
                                isMarkdown={true}
                                prop="baselineImpactPathwayDescription"
                            />
                        )}
                        <WidgetAPIView
                            name="PathwayChart"
                            param={`${pi.baselineImpactPathway.valueOf()}:${impactCategory}`}
                        />
                    </div>
                )}
            {("showAmountPathway" in pi ? pi.showAmountPathway : true) && pi.amountPathway && (
                <div
                    style={{
                        backgroundColor: lightBlueBg,
                        padding: 32,
                        marginTop: 16,
                        borderRadius: 8,
                    }}
                >
                    <div style={{ fontWeight: "bold", marginBottom: 16 }}>Amount pathway (%)</div>
                    {"amountPathwayDescription" in pi && (
                        <EditableText obj={pi} isMarkdown={true} prop="amountPathwayDescription" />
                    )}
                    <WidgetAPIView
                        name="PathwayChart"
                        param={`${pi.amountPathway!.valueOf()}:${impactCategory}`}
                    />
                </div>
            )}
        </>
    )
}

const thousandsSeparated = new Intl.NumberFormat("en-US", {
    useGrouping: true,
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
})
