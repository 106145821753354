/* eslint-disable no-useless-escape */
export function getFunctionArgNames(func: Function) {
    const code = func.toString()
    const match = code.startsWith("async function")
        ? /^async\s*function\s*[^\(]*\(\s*([^\)]*)\)/m
        : /^function\s*[^\(]*\(\s*([^\)]*)\)/m

    const m = func
        .toString()
        .replace(/((\/\/.*$)|(\/\*[\s\S]*?\*\/)|(\s))/gm, "")
        .match(match)

    if (!m) return []

    return m[1]
        .split(/,/)
        .filter((x) => x.length)
        .map((x) => x.split("=")[0]) // Remove default values
}

import pluralize from "pluralize"
import Prando from "prando"
import { TSQFunction } from "./TSQ/TSQFunction"

const whitelistedCamels = ["iOS", "undefined", "null"]

export function Assert<T>(value: T | undefined | null): T {
    if (value === undefined) throw new Error("Unexpected undefined")
    if (value === null) throw new Error("Unexpected null")
    return value
}

/**
 * Awaits each promise in the provided array sequentially, returning an array of
 * the results.
 *
 * This is similar to Promise.all, but it waits for each promise to resolve
 * before starting the next one, minimizing the risk of conflicts between
 * the operations.
 */
export async function MapAsyncSequentially<T, U>(
    arr: T[],
    mapper: (item: T, index: number) => Promise<U>
): Promise<U[]> {
    const res: U[] = []
    for (let i = 0; i < arr.length; i++) {
        res.push(await mapper(arr[i], i))
    }
    return res
}

/** Converts a camel case identifier to a pretty-printed string */
export function prettyCamel(identifier: string, capitalizeFirstChar = true, count = 1) {
    if (!(typeof identifier === "string")) return identifier
    if (identifier.indexOf("_") !== -1) return identifier
    if (identifier.toUpperCase() === identifier) return identifier
    if (whitelistedCamels.some((x) => x === identifier)) return identifier
    if (identifier.toLowerCase() === "uuid") return "item"

    identifier = identifier.replace("<", " ")
    identifier = identifier.replace(">", "")

    const parts = splitCamelCaseIdentifier(identifier)
        .map((x, i, arr) => (i === arr.length - 1 && count !== 1 ? pluralize(x) : x))
        .map((x, i) =>
            i === 0 && capitalizeFirstChar ? capitalizeFirstLetter(x) : x.toLocaleLowerCase()
        )
        .map((x) => {
            const abb = prettyCamel.abbreviations.find((p) => x.toLowerCase() === p.toLowerCase())
            if (abb) return abb
            return x
        })

    // Replace "Marketing airline i d" -> "Marketing airline ID"
    prettyCamel.abbreviations.forEach((p) => {
        for (let i = 0; i < parts.length - p.length + 1; i++) {
            let match = true
            for (let n = 0; n < p.length; n++) {
                if (parts[i + n].toLowerCase() !== p[n].toLowerCase()) {
                    match = false
                    break
                }
            }
            if (match) {
                parts.splice(i + 1, p.length - 1)
                parts[i] = p
            }
        }
    })

    // Combine two or more individual characters back to uppercase abbreviation
    for (let i = 0; i < parts.length - 1; i++) {
        if (parts[i].length === 1) {
            for (let n = i + 1; n < parts.length; n++) {
                if (parts[n].length === 1) {
                    parts[i] = (parts[i] + parts[n]).toUpperCase()
                    parts[n] = ""
                } else {
                    break
                }
            }
        }
    }

    let res = parts.filter((x) => !!x).join(" ")

    // Revert ugly spaces inserted after parens
    while (res.indexOf("( ") !== -1) {
        res = res.replace("( ", "(")
    }

    return res
}
/** List of words that prettyCamel should not abbreviate */
prettyCamel.abbreviations = ["ID", "API"]

export function splitCamelCaseIdentifier(identifier: string) {
    return identifier.split(/(?=[A-Z])/)
}

export function assertNever(e: never): never {
    throw new Error("Unhandled scenario (assertNever failed)")
}

export function capitalizeFirstLetter(s: string) {
    if (s.length === 0) return s
    return s[0].toUpperCase() + s.slice(1)
}

export function lowercaseFirstLetter(s: string) {
    if (s.length === 0) return s
    return s[0].toLowerCase() + s.slice(1)
}

export function undefinedIfEmpty<T>(arr: T[]): T[] | undefined {
    return arr.length > 0 ? arr : undefined
}

export function flatten<T>(arr: (T | T[])[]): T[] {
    const res: T[] = []
    arr.forEach((x) => (x instanceof Array ? x.forEach((y) => res.push(y)) : res.push(x)))
    return res
}
export function flatMap<TInput, TOutput>(
    arr: TInput[],
    mapper: (item: TInput) => TOutput | TOutput[]
): TOutput[] {
    return flatten(arr.map(mapper))
}

export function Sum(arr: number[]): number {
    return arr.reduce((a, b) => a + b, 0)
}

export function GroupByMap<T, TKey>(arr: T[], selector: (item: T) => TKey) {
    const groups = new Map<TKey, T[]>()
    arr.forEach((item) => {
        const key = selector(item)
        if (!groups.has(key)) {
            groups.set(key, [])
        }
        Assert(groups.get(key)).push(item)
    })
    return groups
}

TSQFunction(GroupBy)
/**
 * Groups the provided array by the provided selector, returning an array of
 * objects with the key and the values.
 */
export function GroupBy<T, TKey>(
    /** The array of items to group */
    arr: T[],
    /** A function that selects the key of the group an item belongs to. */
    selector: (item: T) => TKey
) {
    return Array.from(GroupByMap(arr, selector)).map((e) => ({ key: e[0], values: e[1] }))
}

/** Maps all the values (and keys) of a Map array to a new Map with the same keys. */
export function projectMap<TKey, TValue, TOutValue>(
    map: Map<TKey, TValue>,
    mapper: (value: TValue, key: TKey) => TOutValue
): Map<TKey, TOutValue> {
    const res = new Map<TKey, TOutValue>()
    for (const [key, value] of map) {
        res.set(key, mapper(value, key))
    }
    return res
}
/** Returns a new map sorted by the keys in the provided map. */
export function orderMapByKey<TValue>(map: Map<number, TValue>): Map<number, TValue> {
    const keys = Array.from(map.keys()).sort((a, b) => a - b)
    return new Map(keys.map((key) => [key, Assert(map.get(key))]))
}

export function ellipsize(s: undefined, maxLength: number, ellipsis?: string): undefined
export function ellipsize(s: string, maxLength: number, ellipsis?: string): string
export function ellipsize(
    s: string | undefined,
    maxLength: number,
    ellipsis = "..."
): string | undefined {
    if (s === undefined) return undefined
    if (s.length > maxLength) {
        let i = maxLength - ellipsis.length
        for (; i >= 0; i--) {
            if (s[i] === " ") break
        }
        if (i === 0) i = maxLength - ellipsis.length
        return s.slice(0, i) + ellipsis
    }
    return s
}

/** Returns the item from the array that gets the highest score using the scoring function. */
export function max<T>(arr: T[] | undefined, score: (item: T) => number): T | undefined {
    if (!arr) return undefined
    if (arr.length === 0) return undefined
    let maxItem = arr[0]
    let maxValue = score(arr[0])
    for (let i = 1; i < arr.length; i++) {
        const s = score(arr[i])
        if (s > maxValue) {
            maxValue = s
            maxItem = arr[i]
        }
    }
    return maxItem
}

/** Returns the item from the array that gets the lowest score using the scoring function. */
export function min<T>(arr: T[] | undefined, score: (item: T) => number): T | undefined {
    if (!arr) return undefined
    if (arr.length === 0) return undefined
    let minItem = arr[0]
    let minValue = score(arr[0])
    for (let i = 1; i < arr.length; i++) {
        const s = score(arr[i])
        if (s < minValue) {
            minValue = s
            minItem = arr[i]
        }
    }
    return minItem
}

export function setsEqual<T>(a: T[], b: T[]) {
    return a.every((x) => b.some((y) => x === y)) && b.every((x) => a.some((y) => x === y))
}

const rnd = new Prando(3)

export function pickRandom<T>(arr: T[], ...except: T[]): T {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, no-constant-condition
    while (true) {
        const i = Math.floor(rnd.next() * arr.length)
        const it = arr[i]
        if (except.indexOf(it) !== -1) continue
        return it
    }
}

export function MaxBy<T>(arr: T[], metric: (obj: T) => number): T | undefined {
    let maxValue = Number.NEGATIVE_INFINITY
    let maxObj: T | undefined

    arr.forEach((v) => {
        const value = metric(v)
        if (value > maxValue) {
            maxValue = value
            maxObj = v
        }
    })
    return maxObj
}
/** Returns the object that scores the lowest by the provided metric */
export function MinBy<T>(arr: T[], metric: (obj: T) => number): T | undefined {
    let minValue = Number.POSITIVE_INFINITY
    let minObj: T | undefined

    arr.forEach((v) => {
        const value = metric(v)
        if (minObj === undefined || value < minValue) {
            minValue = value
            minObj = v
        }
    })
    return minObj
}

/**
 * Parse a localized number to a float.
 */
export function parseFormattedNumber(
    stringNumber: string,
    fmt: Intl.NumberFormat = new Intl.NumberFormat()
) {
    const thousandSeparator = fmt.format(11111).replace(/\p{Number}/gu, "")
    const decimalSeparator = fmt.format(1.1).replace(/\p{Number}/gu, "")

    return parseFloat(
        stringNumber
            .replace(new RegExp("\\" + thousandSeparator, "g"), "")
            .replace(new RegExp("\\" + decimalSeparator), ".")
    )
}

export type UnPromisify<T> = T extends Promise<infer U> ? U : T
export function range<T>(
    fromInclusive: number,
    toInclusive: number,
    mapper: (x: number) => T
): T[] {
    const res: T[] = []
    for (let i = fromInclusive; i <= toInclusive; i++) {
        res.push(mapper(i))
    }
    return res
}

/** Computes the set of top-level fields that differ between two objects. */
export function GetChangedFields<T extends object>(oldObj: T, newObj: T): (keyof T & string)[] {
    const changedFields = new Set<keyof T & string>()
    for (const key in newObj) {
        if (JSON.stringify(newObj[key]) !== JSON.stringify(oldObj[key])) {
            changedFields.add(key)
        }
    }
    for (const key in oldObj) {
        if (JSON.stringify(newObj[key]) !== JSON.stringify(oldObj[key])) {
            changedFields.add(key)
        }
    }
    return Array.from(changedFields)
}

/** Returns whether the provided arrays have any shared items. */
export function AnyOverlap<T>(a: T[], b: T[]) {
    const setB = new Set(b)
    return a.some((item) => setB.has(item))
}

/** Returns a new function that memoizes the provided function. */
export function memo<T, TRes>(func: (t: T) => TRes) {
    const cache = new Map<T, TRes>()
    return (t: T) => {
        if (cache.has(t)) return Assert(cache.get(t))
        const res = func(t)
        cache.set(t, res)
        return res
    }
}

/**
 * Maps an array of keys to an object with the same keys and values returned by
 * the provided function.
 */
export function MapKeysToObject<T>(
    keys: string[],
    f: (key: string, index: number) => T
): { [key: string]: T } {
    const result: { [key: string]: T } = {}
    keys.forEach((key, i) => {
        result[key] = f(key, i)
    })
    return result
}

/**
 * Filters an array so it only contains unique items based on the provided
 * selector.
 */
export function UniqueBy<T>(items: T[], selector: (item: T) => any) {
    const seen = new Set<any>()
    return items.filter((item) => {
        const key = selector(item)
        if (seen.has(key)) return false
        seen.add(key)
        return true
    })
}

/**
 * Removes duplicates from the provided array while preserving the order of the
 * items.
 */
export function removeDuplicatesPreserveOrder<T>(arr: T[]): T[] {
    return arr.filter((item, index) => arr.indexOf(item) === index)
}

/**
 * Returns a type predicate that asserts the value is present.
 * Useful for filtering out null and undefined values.
 * @param value
 * @returns
 * @example
 * const values = [1, null, 2, undefined, 3]
 * const presentValues = values.filter(IsNotNullish) // [1, 2, 3] , type is number[]
 */
export function IsNotNullish<T>(value: T | null | undefined): value is T {
    return value !== null && value !== undefined
}
