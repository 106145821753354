import React, { createContext, useContext } from "react"
import { ObjectPath } from "../../reactor/ObjectPath"
import { AreTypesAssignable, Property, Type } from "../../reactor/Types/Type"
import { DocumentContext } from "./DocumentContext"

export type ObjectContext = {
    obj: any
    name: string | number
    type: Type
    parent: ObjectContext | null
}
export const ObjectContext = createContext<ObjectContext | null>(null)

export function ObjectPathProvider({
    name,
    obj,
    type,
    children,
}: {
    obj: any
    type: Type
    name: string | number
    children: React.ReactNode
}) {
    const objectContext = {
        obj,
        name,
        type,
        parent: useContext(ObjectContext),
    }

    // Protects against infinite object loops
    let chain = 0
    let p: ObjectContext | null = objectContext
    while (p && chain < 10) {
        p = p.parent
        chain++
    }
    if (chain > 9) {
        throw new Error("Too deep object path")
    }

    return <ObjectContext.Provider value={objectContext}>{children}</ObjectContext.Provider>
}

export function useFindParentObjectPath() {
    const objectContext = useContext(ObjectContext)
    return (type: Type) => {
        const p = findParentObject(type, objectContext)
        return p && pathOf(p)
    }
}
function pathOf(context: ObjectContext): ObjectPath {
    if (context.parent) return [...pathOf(context.parent), context.name]
    else return [context.name]
}

function findParentObject(type: Type, context: ObjectContext | null): ObjectContext | undefined {
    if (!context) return
    if (AreTypesAssignable(type, context?.type)) {
        return context
    }
    return findParentObject(type, context.parent)
}

export function useObjectPath() {
    const objectContext = useContext(ObjectContext)
    const path: ObjectPath = []
    let context = objectContext
    while (context) {
        path.unshift(context.name)
        context = context.parent
    }
    return path
}

/**
 * `"readonly"` - All fields are readonly no matter what.
 * `"edit"` - Readonly fields are readonly
 * `"create"` - Readonly fields are editable because the object is under creation
 */
export const ReadonlyContext = createContext<{
    mode: "readonly" | "create" | "edit"
    /** Whether we are currently in a part of the subtree where we have explicit
     * update permission. This is typically because an ancestral field is in the
     * explicitly updateable set of fields. */
    updateableSubtree?: boolean
}>({ mode: "edit" })

/** Determines whether a property is readonly in the current object context.
 */
export function useIsReadonlyField(
    property: Property,
    /** Setting this to true  allows filling in illegal readonly blanks, e.g. when adding a new
        blank item in a list with readonly fields.
    */
    needsEditing?: boolean
): boolean {
    const { mode, updateableSubtree } = useContext(ReadonlyContext)
    const docContext = useContext(DocumentContext)
    const canUpdate = useCanUpdateField(property.name)

    switch (mode) {
        case "readonly":
            return true // readonly
        case "create":
            return false // not readonly
        case "edit": {
            if (property.isLocked || property.isReadonly) {
                if (!needsEditing) return true // readonly
            }
        }
    }

    if (!docContext) {
        // no DocumentContext, but ReadonlyContext says edit, so not readonly
        return false
    }

    // Are we in an explicitly updateable subtree context?
    if (updateableSubtree) return false // not readonly

    return !canUpdate // If we can't update, its readonly
}

/**
 * Checks whether the user may edit the field with the given name,
 * according to the document context and current position in the object tree.
 * @param fieldName
 * @returns
 */
export function useCanUpdateField(fieldName: string): boolean {
    const path = useObjectPath()
    const docContext = useContext(DocumentContext)
    if (!docContext) return true

    // Do we have carte blanche update permissions?
    const canUpdate = docContext?.canUpdate ?? false
    if (canUpdate === true) return true

    // For now, we only support field-specific permissions at the top-level
    if (path.length === 0) {
        // Do we have specific update permissions?
        const canUpdateThisParticularField =
            canUpdate instanceof Array && canUpdate.includes(fieldName)
        if (canUpdateThisParticularField) return true
    }

    // No update permissions
    return false
}
