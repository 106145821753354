import { OpaqueString } from "./Opaque"
import { Property } from "./Type"

/** A string that holds TypeScript source code that can be executed in a certain environment.
 *
 *  Use the `@declarations` attribute to point to a function that can emit declarations
 *  to populate intellisense with legal symbols.
 *
 *  @param T The expected type resulting from evaluating the source code
 */
export type TypeScript<TArg, TResult> = OpaqueString<"TypeScript">
export function TypeScript<TArg, TResult>(source: string) {
    return source as any as TypeScript<TArg, TResult>
}

export type TypeScriptEditor<T> = (props: { obj: T; prop: keyof T }) => JSX.Element

/**
 * A list of functions that can be used to launch a custom editor for a
 * TypeScript property. Use the `@editor FunctionName` attribute to point to a
 * function.
 */
TypeScript.editors = [] as TypeScriptEditor<any>[]

export function FindTypeScriptEditor(name: string) {
    return TypeScript.editors.find((e) => e.name.includes(name))
}

/** Registers a function that may be used to render declaration code to help with validation and
 * intellisense when editing TypeScript in Reactor Studio.q */
export function TypeScriptDeclarations(
    func: (document: any, object: any) => string | readonly Property[]
) {
    TypeScriptDeclarations.functions.push(func)
}
TypeScriptDeclarations.functions = [] as ((
    document: any,
    object: any
) => string | readonly Property[])[]
