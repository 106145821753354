import { AST } from "./AST"

export function SerializeAST(node: AST.Node): string {
    if (node.kind === "ArrayLiteral") {
        return `[${node.elements.map(SerializeAST).join(", ")}]`
    } else if (node.kind === "ObjectLiteral") {
        return `{${Object.entries(node.props)
            .map(([key, value]) => `${JSON.stringify(key)}: ${SerializeAST(value)}`)
            .join(", ")}}`
    } else if (node.kind === "Literal") {
        return JSON.stringify(node.value)
    } else if (node.kind === "StringTemplate") {
        return `\`${node.parts
            .map((part) => {
                if (typeof part === "string") {
                    return part
                } else {
                    return `\${${SerializeAST(part)}}`
                }
            })
            .join("")}\``
    } else if (node.kind === "Unknown") {
        return node.code
    } else {
        throw new Error(`Unhandled AST node kind: ${(node as any).kind}`)
    }
}
