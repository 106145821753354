import { Markdown } from "../../reactor/Types/Primitives/Markdown"
import { Uuid } from "../../reactor/Types/Primitives/Uuid"
import { EmailSection } from "./EmailSection"

export type EmailTemplate = {
    name: string
    /**
     * A file path, relative to the project root, to the HTML file that contains
     * the email template, e.g. "models/redoit/email/email.html"
     */
    htmlFileName: string
    render?(
        id: Uuid<"SentMail">,
        sections: Markdown | EmailSection[],
        trackingConsent: boolean
    ): string
}

export function EmailTemplate(et: EmailTemplate) {
    const existing = EmailTemplate.templates.find((t) => t.name === et.name)
    if (existing) {
        if (existing.render) throw new Error(`Email template ${et.name} already has a renderer`)
        existing.render = et.render
    } else {
        EmailTemplate.templates.push(et)
    }
}
/**
 * Low-level email template registry.
 *
 * It is recommended to use the `EmailTemplate` function to register a new
 * template instead of using this directly.
 */
EmailTemplate.templates = [] as EmailTemplate[]
