import React, { useContext, useState } from "react"
import { PropView, PropViewMode } from "./PropView"
import { Col, Form, Row } from "react-bootstrap"
import { DiagnosticView, useDiagnostic } from "./DiagnosticView"
import {
    ObjectPathProvider,
    ReadonlyContext,
    useCanUpdateField,
    useIsReadonlyField,
    useObjectPath,
} from "./ObjectContext"
import { ToolButton } from "./ToolButton"
import { prettyCamel } from "../../reactor/Helpers"
import { RButton } from "./Buttons"
import { PropRow } from "./PropRow"
import { ErrorContext } from "./ErrorContext"
import { WidgetView } from "../../packages/widgets/WidgetView"
import { SchemaToType } from "../../packages/type-extender/TypeExtender"
import { UntitledUI } from "../../packages/untitled-ui"
import { None } from "./None"
import { useDocumentContext } from "./DocumentContext"
import { ClientSideLocalize } from "../../packages/localization/client-side/Dictionary"
import { ColorStyles } from "../../packages/ui"
import {
    GetTypeAlias,
    GetTypeProps,
    IsArrayType,
    IsObjectType,
    IsStringLiteral,
    Property,
    Type,
    TypeToString,
    VisitType,
} from "../../reactor/Types/Type"
import { CreateDefault, DefaultDefaults, UninitializedDefaults } from "../../reactor/Types/Defaults"
import { IsUuidType } from "../../reactor/Types/Primitives/Uuid"
import type { StudioTypeExtensions } from "../API/DocumentPageAPI"
import { useEditableContext } from "../../packages/editing/EditableContext"

export function ObjectPropView({
    label,
    buttons,
    isEmbedded,
    property,
    obj,
}: {
    label?: string
    buttons: ToolButton[]
    isEmbedded: boolean
    obj: any
    property: Property
}) {
    const value = obj[property.name]
    const expand = !!property.tags?.expand
    const [show, setShow] = useState(!label || expand)
    const ec = useEditableContext()
    if (!value && show && !expand) {
        setShow(false)
    }
    const explicitlyUpdateable = useCanUpdateField(property.name)
    const readonlyContext = useContext(ReadonlyContext)
    const isReadonly = useIsReadonlyField(property)

    const unwrap = !!property.tags?.unwrap
    const objectView = (
        <ObjectView
            mode={unwrap ? "root" : "card-no-header"}
            property={property}
            obj={obj[property.name]}
            replaceObj={(o) => (obj[property.name] = o)}
            buttons={[]}
        />
    )

    if (unwrap) return objectView

    return (
        <PropRow
            isReadonly={isReadonly}
            label={label}
            buttons={buttons}
            isEmbedded={isEmbedded}
            description={property.description}
            descriptionAbove={true}
            badge={
                <>
                    {value && label && !expand && (
                        <RButton
                            chevron={!show ? "chevron-down" : "chevron-up"}
                            onClick={() => setShow(!show)}
                        ></RButton>
                    )}
                    {!value && !isReadonly && (
                        <RButton
                            variant="secondary"
                            onClick={() => {
                                obj[property.name] = CreateDefault(
                                    property.type,
                                    UninitializedDefaults
                                )
                                ec.invalidate()
                                setShow(true)
                            }}
                        >
                            Specify
                        </RButton>
                    )}
                </>
            }
        >
            {!value && isReadonly && <None />}
            {show && (
                <ReadonlyContext.Provider
                    value={{
                        mode: isReadonly ? "readonly" : readonlyContext.mode,
                        updateableSubtree:
                            readonlyContext.updateableSubtree || explicitlyUpdateable,
                    }}
                >
                    {objectView}
                </ReadonlyContext.Provider>
            )}
        </PropRow>
    )
}

export function ObjectView({
    obj,
    replaceObj,
    property,
    filter,
    buttons,
    header,
    mode,
}: {
    mode: PropViewMode
    property: Property
    /** If specified, only properties that pass this predicate will be displayed.*/
    filter?: (p: Property) => boolean
    /** The actual object being presented in this view.
     *
     *  Can be wrong wrt the type
     */
    obj: unknown
    /** A function that replaces the object presented in this view. */
    replaceObj: (newObj: Object | undefined) => void
    buttons: ToolButton[]
    header?: JSX.Element
}) {
    const { type } = property
    const alias = GetTypeAlias(type)
    const props = GetTypeProps(type)

    const buttonsJsx = buttons.map((b) => (
        <RButton
            key={b.text}
            style={{ marginLeft: "1rem" }}
            variant={b.variant}
            onClick={b.onClick}
            disabled={b.disabled}
        >
            {b.text}
        </RButton>
    ))

    const head = (
        <div
            style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div style={{ flex: 1 }}>{header || alias}</div>
            {buttonsJsx}
        </div>
    )

    const ec = useEditableContext()

    if (obj === undefined) {
        const a = prettyCamel(TypeToString(type), false)
        const b = prettyCamel(property.name, false)
        return (
            <RButton
                variant="secondary"
                onClick={() => {
                    replaceObj(
                        CreateDefault(type, {
                            ...DefaultDefaults,
                            array: undefined,
                            string: undefined,
                            number: undefined,
                        })
                    )
                    ec.invalidate()
                }}
            >
                {a !== b ? `Specify ${a} for ${b}` : `Specify ${a}`}
            </RButton>
        )
    }

    const optionalize = typeof type === "string" ? false : !!property.tags?.optionalize

    let body = PropsToForm(
        props,
        type,
        obj,
        optionalize,
        filter,
        mode !== "root" ? property.name : undefined
    )

    if (mode !== "root") {
        body = (
            <ObjectPathProvider obj={obj} type={type} name={property.name}>
                {body}
            </ObjectPathProvider>
        )
    }

    if (mode === "root") {
        return body
    }
    if (mode === "inline") {
        return (
            <>
                <div style={{ flexDirection: "row" }}>
                    <Form.Label column sm="2">
                        {header}
                    </Form.Label>
                    <div style={{ paddingTop: 7 }}>
                        {property.description && (
                            <Form.Text className="text-muted">{property.description.en}</Form.Text>
                        )}
                        <DiagnosticView property={property} value={obj} />
                    </div>
                </div>
                <Row style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                    <Col>{body}</Col>
                    <Col md="auto">{buttonsJsx}</Col>
                </Row>
            </>
        )
    } else if (mode === "card-no-header") {
        return (
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    borderRadius: 8,
                    flexDirection: "column",
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: UntitledUI.colorStyles.gray[200],
                    paddingBottom: "1rem",
                }}
            >
                <DiagnosticView property={property} value={obj} />
                {body}
            </div>
        )
    } else {
        return (
            <div className="card">
                <div className="card-header">
                    {head}
                    <DiagnosticView property={property} value={obj} />
                </div>

                <div
                    className="card-body"
                    style={{ paddingLeft: 20, marginRight: 20, width: "100%" }}
                >
                    {body}
                </div>
            </div>
        )
    }
}

export function PropsToForm(
    props: readonly Property[],
    type: Type,
    obj: any,
    optionalize: boolean,
    filter?: (p: Property) => boolean,
    leafName?: string | number
) {
    const docContext = useDocumentContext()
    const { error } = useContext(ErrorContext)
    const diagnostic = useDiagnostic(type, obj)
    const path = useObjectPath()
    if (leafName !== undefined) path.push(leafName)

    // Look up the extra fields defined for this path
    let typeExtensionsTree: StudioTypeExtensions | undefined = docContext?.typeExtensions
    for (const p of path) {
        typeExtensionsTree = typeExtensionsTree?.subtree ? typeExtensionsTree.subtree[p] : undefined
    }
    const typeExtensions = typeExtensionsTree?.extensions

    // The widget representation for props that have it. If the same widget is found multiple times,
    // we should only render it once, hence using a Set
    const widgets = new Set<string>()

    const propViews = props
        .filter((p) => {
            // Hide the property if its @toggle is falsy
            const toggle = p.tags?.toggle
            if (toggle && !obj[toggle]) {
                return false
            }
            return true
        })
        .filter((p) => !filter || filter(p))
        .map((p) => {
            const widget = p.tags?.widget
            if (widget) {
                widgets.add(widget)
            }

            if (IsStringLiteral(p.type) && !p.optional) {
                // This is a constant value that cannot be changed, i.e. a type brand
                // This should not be presented in the UI
                return <React.Fragment key={p.name} />
            }

            if (!docContext?.showAllProps && ShouldHide(p)) {
                return <React.Fragment key={p.name} />
            }
            const section = p.tags?.section

            const trans = { en: prettyCamel(p.name), ...p.tags?.translation }
            const label = trans ? ClientSideLocalize(trans) : p.tags?.label || prettyCamel(p.name)

            if (optionalize) {
                p = { ...p, optional: true }
            }

            return (
                <ErrorContext.Provider
                    key={p.name}
                    value={{
                        error: error || !!diagnostic?.fields?.some((x) => x === p.name),
                    }}
                >
                    <React.Fragment>
                        {section && (
                            <div
                                key={section}
                                className="reactor-form-section-header"
                                style={{
                                    marginTop: 64,
                                }}
                            >
                                {section}
                            </div>
                        )}
                        <PropView
                            mode="inline"
                            obj={obj}
                            key={p.name}
                            property={p}
                            label={label}
                            buttons={[]}
                        />
                    </React.Fragment>
                </ErrorContext.Provider>
            )
        })

    const extensionSections = (
        <div>
            <div style={{ height: 16 }} />
            {typeExtensions?.map((extension) => {
                const extraProps = extension.fields
                    // Don't display extended fields that alias those defined in the model - the model
                    // definition takes precedence
                    ?.filter((ef) => !props.some((p) => p.name === ef.name))
                    .map((ef) => ({
                        name: ef.name,
                        label: ef.label,
                        type: SchemaToType(ef.schema),
                        description: { en: ef.description },
                        optional: !ef.required,
                        tags: ef.tags,
                    }))
                const extendedFields = filter ? extraProps?.filter(filter) : extraProps
                if (extendedFields && extendedFields.length) {
                    const color =
                        extension.color === undefined
                            ? UntitledUI.randomSecondaryColor(extension.title || "")
                            : extension.color !== "none"
                              ? ColorStyles[extension.color]
                              : "none"
                    return (
                        <div
                            style={{
                                borderColor: color === "none" ? undefined : color[200],
                                borderWidth: 1,
                                borderStyle: "solid",
                                marginTop: 16,
                                paddingBottom: 16,
                                borderRadius: 8,
                            }}
                        >
                            {extension.title && (
                                <div
                                    style={{
                                        fontSize: 12,
                                        padding: 16,
                                        color: color === "none" ? undefined : color[500],
                                        backgroundColor: color === "none" ? undefined : color[25],
                                        borderTopLeftRadius: 8,
                                        borderTopRightRadius: 8,
                                    }}
                                >
                                    {extension.title}
                                </div>
                            )}
                            <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                                {extendedFields.map((ef) => (
                                    <PropView
                                        obj={obj}
                                        mode="inline"
                                        label={ef.label ?? prettyCamel(ef.name)}
                                        buttons={[]}
                                        property={ef}
                                    />
                                ))}
                            </div>
                        </div>
                    )
                }
            })}
        </div>
    )

    return (
        <>
            {Array.from(widgets.values()).map((w) => {
                let n: any = docContext?.widgets
                for (const p of path) {
                    n = n ? n[p] : undefined
                }
                n = n ? n[w] : undefined
                return <WidgetView key={w} value={n} />
            })}
            {propViews}
            {extensionSections}
        </>
    )
}

export function ShouldHide(p: Property) {
    return (
        !!p.tags?.hide ||
        (IsArrayType(p.type) && p.tags?.subtree) ||
        (IsUuidType(p.type) && p.isReadonly && !(typeof p.type === "object" && p.type.reference))
    )
}

export function ContainsShouldHideProps(type: Type) {
    let containsShouldHide = false
    VisitType(type, (t) => {
        if (IsObjectType(t)) {
            if (t.props.some(ShouldHide)) {
                containsShouldHide = true
            }
        }
    })
    return containsShouldHide
}
