import { BadRequest } from "../../ErrorCodes"
import { OpaqueString } from "../Opaque"
import { TypeValidator } from "./TypeValidator"

/**
 * A well-formed phone number in E.164 format.
 *
 * This must start with a country code, e.g. +47, and must not contain spaces or
 * illegal characters.
 * @shared
 */
export type PhoneNumber = OpaqueString<"PhoneNumber">
TypeValidator(PhoneNumber)
export function PhoneNumber(pn: string): PhoneNumber {
    if (!pn.startsWith("+")) {
        throw new Error("Phone number must start with a country code, e.g. +47")
    }
    if (pn.includes(" ")) {
        throw new Error("Phone number must not contain spaces")
    }
    // Check for illegal characters, if found, throw an error with the illegal characters
    if (pn.match(/[^0-9+]/)) {
        throw new InvalidPhoneNumberFormat(
            `Phone number contains illegal characters: ${pn.match(/[^0-9+]/)?.join(", ")}`
        )
    }

    return pn as any
}

export class InvalidPhoneNumberFormat extends BadRequest {
    constructor(
        message = "Phone number is not in a valid format - Requires the phone number to be in E.164 format, with a leading +"
    ) {
        super(message)
    }
}
