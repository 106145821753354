import { prettyCamel } from "./Helpers"

export abstract class ErrorCode extends Error {
    /** The problem type. This is a string that uniquely identifies the problem
     * case. */
    readonly type = this.constructor.name
    constructor(
        readonly status: number,
        readonly detail: string
    ) {
        super()
    }
    override get message() {
        return `${this.detail} (${this.status} ${this.description})`
    }

    /** This serializes the ErrorCode exception as compliant with the `application/problem+json` mime
     * type, appropriate for returning in the HTTP response. */
    toJSON() {
        return {
            type: this.type,
            detail: this.detail,
            status: this.status,
        }
    }

    get description() {
        return (this.constructor as any).description || this.type
    }

    /**
     * Whether this is a soft error. This will generate a warning in the server
     * logs, instead of an error.
     *
     * This flag is not serialized to the client, and not reflected in the API
     * spec. It is only used internally on the server to mark the error object
     * as being thrown using `SoftThrow`.
     *
     * The `Reactor-Soft-Error` header is used to indicate to the client that
     * this is a soft error, and that the client can ignore the error by
     * retrying the request with the error hash in the
     * `Reactor-Ignore-Soft-Errors` header.
     *
     */
    isSoft?: boolean
}

export class BadRequest extends ErrorCode {
    constructor(detail: string) {
        super(400, detail)
    }
    static description = "Bad Request"

    /** The diagnostic message for the part of the request that was invalid, if
     *  available.
     *
     *  This is particularly useful in the case of invalid POST operations,
     *  where this message will be the message displayed to the user.
     */
    diagnostic?: string

    withDiagnostic(diagnostic: string): this {
        this.diagnostic = diagnostic
        return this
    }
}

export class BadParameter extends BadRequest {
    constructor(readonly parameter: { [key: string]: any }) {
        super("Bad request parameter")
    }
}

export class Unauthorized extends ErrorCode {
    constructor(detail: string) {
        super(401, detail)
    }

    static description = "Unauthorized"
}

export class Forbidden extends ErrorCode {
    constructor(detail = "Forbidden") {
        super(403, detail)
    }

    static description = "Forbidden"
}

export class NotFound extends ErrorCode {
    constructor(detail = "Not Found") {
        super(404, detail)
    }

    static description = "Not Found"

    /**
     * Generates the default not found message returned by endpoints that use
     * the reference syntax for arguments.
     *
     * This is placed here to ensure consistency between the spec and
     * implementation.
     */
    static referenceNotFoundMessage(typeName: string, paramName: string) {
        return `No ${prettyCamel(typeName, false)} matching the specified ${paramName}`
    }
}

export class RequestTimeout extends ErrorCode {
    constructor(detail: string) {
        super(408, detail)
    }
}

export class Conflict extends ErrorCode {
    constructor(detail: string) {
        super(409, detail)
    }
}

export class Gone extends ErrorCode {
    constructor(detail: string) {
        super(410, detail)
    }
}

export class PreconditionFailed extends ErrorCode {
    constructor(detail: string) {
        super(412, detail)
    }
}

export class UnprocessableContent extends ErrorCode {
    constructor(detail: string) {
        super(422, detail)
    }
}

export class FailedDependency extends ErrorCode {
    constructor(detail: string) {
        super(424, detail)
    }
}

export class TooEarly extends ErrorCode {
    constructor(detail: string) {
        super(425, detail)
    }
}

export class TooManyRequests extends ErrorCode {
    constructor(detail: string) {
        super(429, detail)
    }
}

export class InternalServerError extends ErrorCode {
    constructor(detail: string) {
        super(500, detail)
    }

    static description = "Internal Server Error"
}

export class ServiceUnavailable extends ErrorCode {
    constructor(detail: string) {
        super(503, detail)
    }
}
