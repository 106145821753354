import React, { useContext, useRef, useState } from "react"
import { ellipsize } from "../../reactor/Helpers"
import { Url } from "../../reactor/Types/Primitives/Url"
import { useNavigate } from "../hooks/useNavigate"
import { EditableContext } from "./EditableContext"
import { MustacheReact, MustacheString } from "./Mustache"
import { ClientSideLocalize } from "../localization/client-side/Dictionary"
import { useCurrentLocale } from "../localization/client-side/useLocalize"
import { MarkdownEditor } from "../markdown-edit/MarkdownEdit"
import { MarkdownView } from "../markdown-edit/MarkdownView"

export function EditableText<T, TKey extends keyof T>({
    obj,
    prop,
    className,
    style,
    link,
    defaultText,
    placeholder,
    isMarkdown,
    numberFormat,
    maxLength,
    isLocalized,
    onClick,
    display,
    macros,
    markdownMacros,
}: {
    obj: T
    prop: TKey
    className?: string
    link?: string | Url
    /**
     * The text to display if the object does not have a value for the given
     * property. If the object is localized, the fallback language will be
     * displayed. This value will only be used as a last resort if no other text
     * is available (except for placeholder, which is used in edit mode only)
     */
    defaultText?: string
    /** Text to display - in edit mode only - if the object does not have a
     * value for the given property.*/
    placeholder?: string
    style?: React.CSSProperties
    isMarkdown?: boolean
    numberFormat?: Intl.NumberFormat
    maxLength?: number
    /**
     * Whether this text is localized. If so, the text will be an object with
     * keys for each locale.
     *
     * A `<LocalesProvider>` must be provided in the tree above this component.
     *
     * This property can not change after the component is mounted.
     */
    isLocalized?: boolean
    onClick?: () => void

    /** Defines a set of {{mustache}} macros for rendering the text to React
     *  nodes. This only works for non-markdown text.
     *
     *  For example, if the text is `"A receipt will be sent to {{email}}"`, and
     *  you set this property to `{ email: <b>{foo@bar.com}</b>}`, the text will
     *  be rendered as `"A receipt will be sent to <b>foo@bar.com</b>"`
     *
     *  If this is used, `display` will not be used.
     */
    macros?: Record<string, React.ReactNode>

    markdownMacros?: Record<string, string>

    /** Allows overriding the rendering of the text in display mode.
     *
     *  Allows crazy effects like e.g. rotating the characters of the text
     *  individually, wanted by certain customers.
     *
     *  This property will be ignored if `macros` is used.
     */
    display?: (s: string) => JSX.Element
}) {
    const ref = useRef<HTMLElement>(null)
    const ec = useContext(EditableContext)
    const editing = ec?.editing
    const locale = useCurrentLocale()
    const [hasChanges, setHasChanges] = useState(false)
    const [focused, setFocused] = useState(false)
    const navigate = useNavigate()

    if (macros && isMarkdown) {
        return <div style={{ color: "red" }}>Macros not supported in markdown</div>
    }

    if (hasChanges && !ec?.isDirty) {
        setHasChanges(false)
    }

    let value: any = obj[prop]

    if (isLocalized && value === undefined) {
        value = {}
    }

    const wasLocalized = typeof value === "object"

    if (wasLocalized) {
        value = ClientSideLocalize(value, locale)
    }

    if (!value) value = defaultText
    if (editing && !value) value = placeholder

    if (typeof value === "number") {
        if (numberFormat) {
            value = numberFormat.format(value)
        }
    }
    if (value !== undefined && typeof value !== "string") {
        value = "" + value
    }
    if (!editing && maxLength && typeof value === "string" && value.length > maxLength) {
        value = ellipsize(value, maxLength)
    }

    const giveFocus = editing
        ? () => {
              setFocused(true)
              // Need to wait for re-render, then attempt to give focus to editable content
              setTimeout(() => ref.current?.focus(), 10)
          }
        : undefined

    if (macros) {
        display = (v) => MustacheReact(v, macros)
    }

    const onClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
        if (editing) {
            e.stopPropagation()
            e.preventDefault()
        }
        if (giveFocus) giveFocus()
        else if (onClick) onClick()
        else if (link && !editing) navigate(link.toString())
    }

    return focused ? (
        isMarkdown ? (
            <div className={className} style={style}>
                <MarkdownEditor
                    value={value}
                    onChanged={(newValue) => {
                        if (wasLocalized) {
                            ;(obj as any)[prop] ??= {}
                            ;(obj as any)[prop][locale as any] = newValue
                        } else {
                            ;(obj as any)[prop] = newValue
                        }
                        ec?.invalidate()
                    }}
                    onDone={() => setFocused(false)}
                    onDrop={async (fileDropped) => {
                        return undefined
                    }}
                    style="wysiwyg"
                />

                {editing && !!macros ? <MacrosView macros={macros} /> : undefined}
            </div>
        ) : (
            <textarea
                className={className}
                style={{
                    backgroundColor: "transparent",
                    border: "none",
                    outline: "none",
                    ...style,
                }}
                value={value}
                onBlur={() => setFocused(false)}
                onChange={(e) => {
                    if (wasLocalized) {
                        ;(obj as any)[prop] ??= {}
                        ;(obj as any)[prop][locale as any] = e.target.value
                    } else {
                        ;(obj as any)[prop] = e.target.value
                    }
                    ec?.invalidate()
                }}
            />
        )
    ) : display ? (
        <div onClick={onClickHandler}>{display(value)}</div>
    ) : (
        <div onClick={onClickHandler} className={className} style={style}>
            {isMarkdown ? (
                <MarkdownView
                    value={markdownMacros ? MustacheString(value, markdownMacros) : value}
                />
            ) : (
                value
            )}
        </div>
    )
}

function MacrosView({ macros }: { macros: Record<string, React.ReactNode> }) {
    const [show, setShow] = useState(false)
    return (
        <div
            onMouseOver={() => setShow(true)}
            style={{
                backgroundColor: "#ffa",
                padding: 2,
                borderRadius: 2,
                fontSize: 10,
                width: show ? undefined : 12,
                height: show ? undefined : 12,
            }}
        >
            {show ? (
                <>
                    Available macros:
                    <table>
                        {Object.keys(macros)
                            .filter((m) => !!macros[m])
                            .map((m) => (
                                <tr key={m}>
                                    <td>{`{{${m}}}`}</td>
                                    <td>
                                        <div style={{ padding: 4 }}>{macros[m]}</div>
                                    </td>
                                </tr>
                            ))}
                    </table>
                </>
            ) : (
                <>+</>
            )}
        </div>
    )
}
