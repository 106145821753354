import { useCallback, useEffect, useState } from "react"
import { useEditableContext } from "./EditableContext"

/**
 * Implements the correct pattern for using a state that is bound to the current
 * document state.
 *
 * It returns three values, as a tuple: [state, setState, setStateLocally].
 *
 * `state` is the current state of the property. `setState` updates both the
 * local state, and the document state, and marks the document as dirty.
 *
 * `setStateLocally` updates only the local state, and does not mark the
 * document as dirty. This is useful e.g. when typing in a text box, you want to
 * update the local state immediately, but not update the document state until
 * the user has finished typing.
 *
 */
export function useBoundState<T, TKey extends keyof T, TValue = T[TKey]>(
    obj: T,
    propName: TKey
): [TValue, (v: TValue) => void, (v: TValue) => void] {
    const ec = useEditableContext()
    const savedState = obj[propName] as TValue
    const [state, setStateLocally] = useState<TValue>(savedState)
    const setState = useCallback(
        (v: TValue) => {
            obj[propName] = v as any
            setStateLocally(v)
            ec.invalidate()
        },
        [obj, propName, ec]
    )
    useEffect(() => {
        if (state !== obj[propName]) setStateLocally(obj[propName] as TValue)
        // We are intentionally not including `state` in the dependencies,
        // as local state changes are allowed, and should not trigger this
        // effect.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [obj, propName, obj[propName]])
    return [state, setState, setStateLocally]
}
