import React from "react"
import type { Tabs } from "./Tabs"
import { RegisterWidget, WidgetView } from "./WidgetView"
import { ColorStyles } from "../ui"
import { useQueryString } from "../../reactor/Web/WindowLocation"
import { ClientSideLocalize } from "../localization/client-side/Dictionary"

RegisterWidget<Tabs>("Tabs", ({ value }: { value: Tabs }) => {
    const [tab, setTab] = useQueryString(value.widgetKey ?? "tab", value.tabs[0].slug)

    const selected = value.tabs.findIndex((t) => t.slug === tab)
    const setSelected = (index: number) => {
        setTab(value.tabs[index].slug)
    }

    return (
        <div>
            <ButtonTabBar
                buttons={value.tabs.map((t): string => ClientSideLocalize(t.title))}
                selected={selected}
                setSelected={setSelected}
            />
            <WidgetView value={value.tabs[selected].content} />
        </div>
    )
})

export function ButtonTabBar(props: {
    buttons: string[]
    selected: number
    setSelected: (index: number) => void
}) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                marginTop: 32,
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    fontWeight: 600,
                    border: "1px solid " + ColorStyles.gray[300],
                    borderRadius: 8,
                    marginLeft: 12,
                    marginRight: 12,
                    fontSize: 14,
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {props.buttons.map((title, i) => {
                    return (
                        <div
                            key={title}
                            onClick={() => props.setSelected(i)}
                            style={{
                                cursor: "pointer",
                                paddingLeft: 16,
                                paddingRight: 16,
                                paddingTop: 8,
                                paddingBottom: 8,
                                color: ColorStyles.gray[700],
                                backgroundColor:
                                    i === props.selected ? ColorStyles.gray[100] : undefined,
                                borderTopLeftRadius: i === 0 ? 7 : undefined,
                                borderBottomLeftRadius: i === 0 ? 7 : undefined,
                                borderTopRightRadius:
                                    i === props.buttons.length - 1 ? 7 : undefined,
                                borderBottomRightRadius:
                                    i === props.buttons.length - 1 ? 7 : undefined,

                                borderLeft:
                                    i > 0 ? "1px solid " + ColorStyles.gray[300] : undefined,
                            }}
                        >
                            <div>{title}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
