import {
    AnyType,
    ArrayType,
    AtomicType,
    FileType,
    FunctionType,
    IntersectionType,
    IsAnyType,
    IsArrayType,
    IsAtomic,
    IsFileType,
    IsFunctionType,
    IsIntersectionType,
    IsNeverType,
    IsNullType,
    IsObjectType,
    IsParameterType,
    IsPromiseType,
    IsUndefinedType,
    IsUnionType,
    IsUnknownType,
    IsVoidType,
    NeverType,
    NullType,
    ObjectType,
    ParameterType,
    PromiseType,
    Property,
    Type,
    TypeToString,
    UndefinedType,
    UnionType,
    UnknownType,
    VoidType,
    GetTypeAlias,
    IsAsyncGeneratorType,
    AsyncGeneratorType,
} from "./Type"
export class Visitor {
    private visitedAliases = new Set<string>()
    private visited = new Set<Type>()
    type(t: Type): Type | void {
        if (this.visited.has(t)) {
            return
        }
        this.visited.add(t)

        // For some reason, the above is not enough to prevent all forms of infinite recursion. Also
        // testing for aliases fixes the problems seen so far, but there is probably a better
        // fix(tm) waiting to be found.
        const alias = GetTypeAlias(t)
        if (alias) {
            if (this.visitedAliases.has(alias)) {
                return
            }
            this.visitedAliases.add(alias)
        }

        if (IsUnionType(t)) return this.union(t)
        else if (IsIntersectionType(t)) return this.intersection(t)
        else if (IsObjectType(t)) return this.object(t)
        else if (IsArrayType(t)) return this.array(t)
        else if (IsAtomic(t)) return this.atomic(t)
        else if (IsUndefinedType(t)) return this.undefined(t)
        else if (IsNullType(t)) return this.null(t)
        else if (IsAnyType(t)) return this.any(t)
        else if (IsVoidType(t)) return this.void(t)
        else if (IsParameterType(t)) return this.parameter(t)
        else if (IsNeverType(t)) return this.never(t)
        else if (IsUnknownType(t)) return this.unknown(t)
        else if (IsPromiseType(t)) return this.promise(t)
        else if (IsAsyncGeneratorType(t)) return this.asyncGenerator(t)
        else if (IsFunctionType(t)) return this.func(t)
        else if (IsFileType(t)) return this.file(t)
        else if (t === "symbol") return
        else throw new Error("Unhandled type in Visitor: " + TypeToString(t))
    }
    never(t: NeverType) {}
    unknown(t: UnknownType) {}
    promise(t: PromiseType) {}
    asyncGenerator(t: AsyncGeneratorType) {}
    func(t: FunctionType) {}
    parameter(t: ParameterType) {}
    void(v: VoidType): Type | void {}
    any(t: AnyType): Type | void {}
    null(n: NullType): Type | void {}
    undefined(u: UndefinedType): Type | void {}
    file(f: FileType): Type | void {}
    union(u: UnionType): Type | void {
        let anyChanged = false
        const nu = {
            alias: u.alias,
            documentation: u.documentation,
            typeArgs: u.typeArgs,
            union: u.union.map((x, i) => {
                const nt = this.type(x)
                if (nt) {
                    anyChanged = true
                    return nt
                } else {
                    return x
                }
            }),
        }
        if (anyChanged) return nu
    }
    intersection(i: IntersectionType): Type | void {
        let anyChanged = false
        const ni = {
            alias: i.alias,
            documentation: i.documentation,
            typeArgs: i.typeArgs,
            intersection: i.intersection.map((x) => {
                const nt = this.type(x)
                if (nt) {
                    anyChanged = true
                    return nt
                } else {
                    return x
                }
            }),
        }
        if (anyChanged) return ni
    }
    object(o: ObjectType): Type | void {
        const nap = o.additionalProps && (this.type(o.additionalProps) || o.additionalProps)
        let anyChanged = nap !== o.additionalProps
        const no = {
            alias: o.alias,
            documentation: o.documentation,
            isClass: o.isClass,
            super: o.super,
            typeArgs: o.typeArgs,
            props: o.props.map((p) => {
                const np = this.property(p)
                if (np) {
                    anyChanged = true
                    return np
                } else {
                    return p
                }
            }),
            additionalProps: nap ? nap : undefined,
        }
        if (anyChanged) return no
    }
    property(p: Property): Property | void {
        const type = this.type(p.type)
        if (type) return { ...p, type }
    }
    array(a: ArrayType): Type | void {
        const t = this.type(a.array)
        if (t) return { ...a, array: t }
    }
    atomic(a: AtomicType): Type | void {}
}
