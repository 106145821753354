import { CSSProperties, useEffect, useState } from "react"
import { useEditableContext } from "../../../packages/editing/EditableContext"
import { useHover } from "../../../packages/hooks/useHover"

export function useClickableCard(style: CSSProperties) {
    const { editing } = useEditableContext()
    const { hover, hoverProps, setHover } = useHover()
    const [, setTouched] = useState(false)

    useEffect(() => {
        if (hover) {
            setTouched(true)
        }
    }, [hover])

    return {
        props: {
            ...hoverProps,
            style: {
                cursor: editing ? undefined : "pointer",
                ...style,
            },
        },
        hover,
        setHover,
    }
}
